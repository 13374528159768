import { styled } from "linaria/react";
import React from "react";
import BlogWaveSvg from './assets/blog-wave.svg'

export interface ContactWaveProps {
  className?: string;
}

const Wave = styled.img`
  width: 100%;
  position: absolute;
  top: 100px;
  z-index: -1;
  display: none;

  @media (min-width: 1280px) {
    display: flex;
  }
`;

export const BlogWave = (props: ContactWaveProps) => {
  return <Wave src={BlogWaveSvg} alt="" />;
};
