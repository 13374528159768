import { Link } from "gatsby";
import GatsbyImage from "gatsby-image";
import React from "react";
import { Blog } from "../../../../lib/model/Blog";
import { generateBlogUrl } from "../../../../lib/utils/generate-blog-url";
import removeMd from "remove-markdown";

export interface BlogCardProps extends Blog {
  className?: string;
}

export const BlogCard = (props: BlogCardProps) => {
  const { title, slug, image, content } = props;

  const excerpt = removeMd(content).slice(0, 160) + "...";

  return (
    <Link to={generateBlogUrl(slug)} className={`group flex flex-col justify-between`}>
      <header>
        <div className="image rounded-2xl shadow-xl overflow-hidden h-80 group-hover:shadow-2xl transition-all duration-500 ease-in-out">
          <GatsbyImage className="h-80" fluid={image.childImageSharp.fluid} />
        </div>
        <h1 className="mt-8 font-medium text-2xl text-primary-dark group-hover:text-blue-400 transition-all duration-300 ease-in-out">
          {title}
        </h1>
      </header>
      <p className="text-lg font-thin mt-2 text-gray-500">{excerpt}</p>
    </Link>
  );
};
