import Pagination, {
  PaginationRenderItemParams
} from "@material-ui/lab/Pagination";
import PaginationItem from "@material-ui/lab/PaginationItem";
import { graphql, Link } from "gatsby";
import { css } from "linaria";
import React from "react";
import { BlogCard } from "../../components/Blog/BlogList/BlogCard/BlogCard";
import { BlogWave } from "../../components/Blog/BlogList/BlogWave/BlogWave";
import { Container } from "../../components/Shared/Container/Container";
import { PageHeading } from "../../components/Shared/Headings/PageHeading";
import Layout from "../../components/Shared/Layout/Layout";
import { Blog } from "../../lib/model/Blog";

export interface BlogListTemplateProps {
  className?: string;
  data: any;
  pathContext: any;
}

export const query = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allStrapiBlog(
      skip: $skip
      limit: $limit
      sort: { order: DESC, fields: date }
    ) {
      posts: nodes {
        id
        title
        slug
        content
        author {
          email
          nickname
          username
        }
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        date
      }
    }
  }
`;

const BlogListTemplateInner = (props: BlogListTemplateProps) => {
  const {
    data: {
      allStrapiBlog: { posts },
    },
    pathContext: pagination,
  } = props;

  const getPaginationItemLink = (item: PaginationRenderItemParams) => {
    const pageNumber = item.page === 1 ? "" : item.page;
    return `/blog/${pageNumber}`;
  };

  return (
    <Layout>
      <Container>
        <PageHeading>Blog</PageHeading>
        <div className="blog-main grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-16 sm:gap-10 xl:gap-14">
          {posts.map((post: Blog) => (
            <BlogCard {...post} />
          ))}
        </div>
        <div className="flex justify-center mt-32">
          <Pagination
            page={pagination.humanPageNumber}
            count={pagination.numberOfPages}
            renderItem={(item: PaginationRenderItemParams) => (
              <PaginationItem
                component={Link}
                to={getPaginationItemLink(item)}
                {...item}
                size={"large"}
              />
            )}
          />
        </div>
      </Container>
      <BlogWave />
    </Layout>
  );
};

export default BlogListTemplateInner;
